<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <v-card v-if="!waitingForCode">
      <v-sheet color="secondary lighten-1">
        <v-card-title>{{$t('m.account.phone.title')}}</v-card-title>
      </v-sheet>
      <v-alert tile type="error" v-if="errorMessage">{{errorMessage}}</v-alert>
      <v-card-text>
        <ValidationProvider
          :name="$t('m.account.phone.telno')"
          :rules="{required: true, min: 10, regex: /^[0-9\-]{10,}$/}"
          v-slot="{ errors }"
        >
          <v-text-field
            :disabled="submittingPhoneNumber"
            :error-messages="errors"
            v-model="phoneNumber"
            :label="$t('m.account.phone.telno')"
          />
        </ValidationProvider>
        <div ref="recaptcha" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="submittingPhoneNumber" text @click="$emit('close')">{{$t('m.cancel')}}</v-btn>
        <v-btn
          :disabled="invalid"
          :loading="submittingPhoneNumber"
          @click="onSubmitPhoneNumber"
        >{{$t('m.ok')}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-sheet color="grey lighten-3">
        <v-card-title>{{$t('m.account.phone.title')}}</v-card-title>
      </v-sheet>
      <v-alert tile type="error" v-if="errorMessage">{{errorMessage}}</v-alert>
      <v-card-text>
        <v-row>
          <v-col cols="auto">{{$t('m.account.phone.telno')}}:</v-col>
          <v-col cols="auto">{{phoneNumber}}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <ValidationProvider
          :name="$t('m.account.phone.confirm')"
          :rules="{required: true, min: 6, regex: /^[0-9]{6}$/}"
          v-slot="{ errors }"
        >
          <v-text-field
            :disabled="confirmingCode"
            :error-messages="errors"
            v-model="verificationCode"
            :label="$t('m.account.phone.confirm')"
          />
        </ValidationProvider>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="confirmingCode" text @click="$emit('close')">{{$t('m.cancel')}}</v-btn>
        <v-btn :disabled="invalid" :loading="confirmingCode" @click="onConfirmCode">{{$t('m.ok')}}</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import errorLookups from './errors'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      phoneNumber: null,
      submittingPhoneNumber: false,
      waitingForCode: false,
      confirmingCode: false,
      verificationCode: null,
      rawErrorCode: null
    }
  },
  computed: {
    errorMessage () {
      const lookupResult = errorLookups[this.rawErrorCode]
      return lookupResult || this.rawErrorCode
    }
  },
  methods: {
    ...mapActions('auth', ['beginLinkPhone', 'confirmLinkPhone']),
    async onSubmitPhoneNumber () {
      this.rawErrorCode = null
      this.submittingPhoneNumber = true
      try {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          // await this.linkEmail({ email: this.email, password: this.password })
          await this.beginLinkPhone({ phoneNumber: `+81${this.phoneNumber}`, element: this.$refs.recaptcha })
          this.waitingForCode = true
        }
      } catch (err) {
        console.error('error submitting phone number', err) // eslint-disable-line no-console
        // this.$emit('error', err.code)
        this.rawErrorCode = err.code
      } finally {
        this.submittingPhoneNumber = false
      }
    },
    async onConfirmCode () {
      this.rawErrorCode = null
      this.confirmingCode = true
      try {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.confirmLinkPhone(this.verificationCode)
          this.$emit('close')
        }
      } catch (err) {
        console.error('error confiring phone code', err) // eslint-disable-line no-console
        // this.$emit('error', err.code)
        this.rawErrorCode = err.code
      } finally {
        this.confirmingCode = false
      }
    }
  },
  mounted () {
    this.waitingForCode = false
  }
}
</script>

<style>
</style>
